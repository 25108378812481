<div class="container">
  <div class="row">
    <mat-card appearance="outlined" *ngIf="this.vehicle | async as vehicle">
      <mat-card-header class="vehicle-header">
        <mat-card-title>{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</mat-card-title>
        <mat-card-subtitle>{{ vehicle.color }} | {{ vehicle.plates }}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
    <mat-tab-group>
      <mat-tab label="Fuel">
        <table mat-table [dataSource]="fuelDataSource" matSort>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.date.seconds * 1000 | date: "shortDate" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="priceSummary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
            <td mat-cell *matCellDef="let row">
              {{ row.price | currency }} &#64;<br />{{ row.pricePerGallon | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="mileage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
            <td mat-cell *matCellDef="let row">
              {{ row.odometer }}<br />({{
                row.mpg === "N/A" ? row.mpg : (row.mpg | number: "1.0-0")
              }}
              mpg)
            </td>
          </ng-container>

          <ng-container matColumnDef="mpg">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>MPG</th>
            <td mat-cell *matCellDef="let row">
              {{ row.mpg === "N/A" ? row.mpg : (row.mpg | number: "1.0-1") }}
            </td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
            <td mat-cell *matCellDef="let row">{{ row.price | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="costPerGallon">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>$ / Gallon</th>
            <td mat-cell *matCellDef="let row">{{ row.pricePerGallon | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
            <td mat-cell *matCellDef="let row">{{ row.location }}</td>
          </ng-container>

          <ng-container matColumnDef="grade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fuel Grade</th>
            <td mat-cell *matCellDef="let row">{{ row.grade }}</td>
          </ng-container>

          <ng-container matColumnDef="odometer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Odometer</th>
            <td mat-cell *matCellDef="let row">{{ row.odometer }}</td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef (click)="addFuel()">
              <mat-icon>add</mat-icon>
            </th>

            <td
              mat-cell
              [matMenuTriggerFor]="fuelEditMenu"
              [matMenuTriggerData]="{ fuel: row }"
              *matCellDef="let row"
            >
              <mat-icon>more_vert</mat-icon>
            </td>
            <mat-menu #fuelEditMenu="matMenu">
              <ng-template matMenuContent let-fuel="fuel">
                <button mat-menu-item (click)="editFuel(fuel)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteFuel(fuel)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="fuelColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: fuelColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="fuelColumns.length">
              You haven't recorded any fuel records.
            </td>
          </tr>
        </table>
        <mat-paginator
          #fuelPaginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        ></mat-paginator>
      </mat-tab>
      <mat-tab label="Maintenance">
        <table mat-table [dataSource]="maintenanceDataSource" matSort>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">
              {{ row.date.seconds * 1000 | date: "shortDate" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row">{{ row.category }}</td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
            <td mat-cell *matCellDef="let row">{{ row.location }}</td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Price</th>
            <td mat-cell *matCellDef="let row">{{ row.price | currency }}</td>
          </ng-container>

          <ng-container matColumnDef="odometer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Odometer</th>
            <td mat-cell *matCellDef="let row">{{ row.odometer }}</td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
          </ng-container>

          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef (click)="addMaintenance()">
              <mat-icon>add</mat-icon>
            </th>
            <td
              mat-cell
              [matMenuTriggerFor]="maintenanceEditMenu"
              [matMenuTriggerData]="{ maintenance: row }"
              *matCellDef="let row"
            >
              <mat-icon>more_vert</mat-icon>
            </td>
            <mat-menu #maintenanceEditMenu="matMenu">
              <ng-template matMenuContent let-maintenance="maintenance">
                <button mat-menu-item (click)="editMaintenance(maintenance)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="deleteMaintenance(maintenance)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete</span>
                </button>
              </ng-template>
            </mat-menu>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="maintenanceColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: maintenanceColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="maintenanceColumns.length">
              You haven't recorded any maintenance records.
            </td>
          </tr>
        </table>
        <mat-paginator
          #maintenancePaginator
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        ></mat-paginator>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
