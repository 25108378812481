import { AddFuelComponent } from '../add-fuel/add-fuel.component';
import { AddMaintenanceComponent } from '../add-maintenance/add-maintenance.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  Firestore,
  orderBy,
  query,
} from '@angular/fire/firestore';
import { EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Vehicle, FuelWithId, MaintenanceWithId, DisplayableFuel, Maintenance } from '../types';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['../common-styles.css', './vehicle.component.css'],
})
export class VehicleComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();

  fuelColumns: string[] = [
    'date',
    'mpg',
    'price',
    'costPerGallon',
    'location',
    'grade',
    'odometer',
    'edit',
  ];
  fuelDataSource: MatTableDataSource<DisplayableFuel> = new MatTableDataSource();

  maintenanceColumns: string[] = ['date', 'category', 'location', 'price', 'odometer', 'edit'];
  maintenanceDataSource: MatTableDataSource<Maintenance> = new MatTableDataSource();

  @ViewChild('fuelPaginator') fuelPaginator: MatPaginator | null = null;
  @ViewChild('maintenancePaginator') maintenancePaginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  vehicle: Observable<Vehicle | undefined> = EMPTY;
  fuels: Observable<FuelWithId[] | undefined> = EMPTY;
  maintenance: Observable<MaintenanceWithId[] | undefined> = EMPTY;

  constructor(
    private route: ActivatedRoute,
    public firestore: Firestore,
    public dialog: MatDialog,
    public breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id !== null) {
      const vehicleDoc = doc(this.firestore, 'vehicles', id).withConverter<Vehicle>({
        fromFirestore: (snapshot) => snapshot.data() as Vehicle,
        toFirestore: (it) => it,
      });
      this.vehicle = docData(vehicleDoc);

      const fuelCollection = collection(
        doc(collection(this.firestore, 'vehicles'), id),
        'fuel',
      ).withConverter<FuelWithId>({
        fromFirestore: (snapshot) => {
          const obj = snapshot.data() as FuelWithId;
          obj.id = snapshot.id;
          return obj;
        },
        toFirestore: (it) => it,
      });
      this.fuels = collectionData(query(fuelCollection, orderBy('odometer')));

      this.fuels.subscribe((val) => {
        const list: Array<DisplayableFuel> = [];
        if (val) {
          let lastOdometer: number | undefined = undefined;
          val.forEach((element) => {
            let mpg = 'N/A';
            if (lastOdometer) {
              const gallons: number = element.price / element.pricePerGallon;
              mpg = '' + (element.odometer - lastOdometer) / gallons;
            }
            const newElement: DisplayableFuel = Object.assign({ mpg: mpg }, element);
            list.push(newElement);
            lastOdometer = element.odometer;
          });
        }
        list.reverse();
        this.fuelDataSource.data = list;
        this.fuelDataSource.paginator = this.fuelPaginator;
        this.fuelDataSource.sort = this.sort;
      });

      const maintCollection = collection(
        doc(collection(this.firestore, 'vehicles'), id),
        'maintenance',
      ).withConverter<MaintenanceWithId>({
        fromFirestore: (snapshot) => {
          const obj = snapshot.data() as MaintenanceWithId;
          obj.id = snapshot.id;
          return obj;
        },
        toFirestore: (it) => it,
      });
      this.maintenance = collectionData(query(maintCollection, orderBy('date', 'desc')));
      this.maintenance.subscribe((val) => {
        const list: Array<Maintenance> = [];
        if (val) {
          val.forEach((element) => {
            list.push(element);
          });
        }
        this.maintenanceDataSource.data = list;
        this.maintenanceDataSource.paginator = this.maintenancePaginator;
        this.maintenanceDataSource.sort = this.sort;
      });

      this.breakpointObserver
        .observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
          Breakpoints.Medium,
          Breakpoints.Large,
          Breakpoints.XLarge,
        ])
        .pipe(takeUntil(this.destroyed))
        .subscribe((result) => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              if (query === Breakpoints.XSmall || query == Breakpoints.Small) {
                this.fuelColumns = ['date', 'location', 'priceSummary', 'mileage', 'edit'];
                this.maintenanceColumns = [
                  'date',
                  'category',
                  'price',
                  'odometer',
                  'description',
                  'edit',
                ];
              } else {
                this.fuelColumns = [
                  'date',
                  'mpg',
                  'price',
                  'costPerGallon',
                  'location',
                  'grade',
                  'odometer',
                  'edit',
                ];
                this.maintenanceColumns = [
                  'date',
                  'category',
                  'location',
                  'price',
                  'odometer',
                  'description',
                  'edit',
                ];
              }
            }
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  addFuel() {
    this.dialog.open(AddFuelComponent, {
      width: '70%',
      data: { vehicle: this.route.snapshot.paramMap.get('id') },
    });
  }

  editFuel(fuel: FuelWithId) {
    this.dialog.open(AddFuelComponent, {
      width: '70%',
      data: { vehicle: this.route.snapshot.paramMap.get('id'), fuel: fuel },
    });
  }

  deleteFuel(fuel: FuelWithId) {
    const dialogRef = this.dialog.open(DeleteRecordDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      const vehicleId = this.route.snapshot.paramMap.get('id');
      if (vehicleId !== null && result === true) {
        deleteDoc(
          doc(collection(doc(collection(this.firestore, 'vehicles'), vehicleId), 'fuel'), fuel.id),
        );
      }
    });
  }

  addMaintenance() {
    this.dialog.open(AddMaintenanceComponent, {
      width: '70%',
      data: { vehicle: this.route.snapshot.paramMap.get('id') },
    });
  }

  editMaintenance(maintenance: MaintenanceWithId) {
    this.dialog.open(AddMaintenanceComponent, {
      width: '70%',
      data: { vehicle: this.route.snapshot.paramMap.get('id'), maintenance: maintenance },
    });
  }

  deleteMaintenance(maintenance: MaintenanceWithId) {
    const dialogRef = this.dialog.open(DeleteRecordDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      const vehicleId = this.route.snapshot.paramMap.get('id');
      if (vehicleId !== null && result === true) {
        deleteDoc(
          doc(
            collection(doc(collection(this.firestore, 'vehicles'), vehicleId), 'maintenance'),
            maintenance.id,
          ),
        );
      }
    });
  }
}

@Component({
  selector: 'app-delete-record-dialog',
  template: `
    <h2 mat-dialog-title>Delete?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Are you sure you want to delete this record?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
    </mat-dialog-actions>
  `,
})
export class DeleteRecordDialogComponent {}
