<form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ verb }} Vehicle</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Year</mat-label>
        <input matInput formControlName="year" required />
        <mat-hint>2012</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Make</mat-label>
        <input matInput formControlName="make" required />
        <mat-hint>Ford</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Model</mat-label>
        <input matInput formControlName="model" required />
        <mat-hint>Focus</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Color</mat-label>
        <input matInput formControlName="color" />
        <mat-hint>Red</mat-hint>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>License Plates</mat-label>
        <input matInput formControlName="plates" />
        <mat-hint>123-ABC</mat-hint>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" [disabled]="!vehicleForm.valid" cdkFocusInitial>
      {{ verb }}
    </button>
  </mat-dialog-actions>
</form>
