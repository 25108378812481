import { AddVehicleComponent } from '../add-vehicle/add-vehicle.component';
import { VehicleWithId } from '../types';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  query,
  where,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-garage',
  templateUrl: './garage.component.html',
  styleUrls: ['../common-styles.css', './garage.component.css'],
})
export class GarageComponent implements AfterViewInit {
  displayedColumns: string[] = ['year', 'make', 'model', 'edit'];
  dataSource: MatTableDataSource<VehicleWithId> = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  constructor(
    public dialog: MatDialog,
    public firestore: Firestore,
    public auth: Auth,
  ) {}

  ngAfterViewInit() {
    authState(this.auth).subscribe((user) => {
      const vehicleCollection = collection(this.firestore, 'vehicles').withConverter<VehicleWithId>(
        {
          fromFirestore: (snapshot) => {
            const obj = snapshot.data() as VehicleWithId;
            obj.id = snapshot.id;
            return obj;
          },
          toFirestore: (it) => it,
        },
      );
      collectionData(query(vehicleCollection, where('owner', '==', user?.uid))).subscribe((val) => {
        this.dataSource.data = val;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editVehicle(vehicle: VehicleWithId) {
    this.dialog.open(AddVehicleComponent, {
      width: '70%',
      data: { vehicle: vehicle },
    });
  }

  deleteVehicle(vehicle: VehicleWithId) {
    const dialogRef = this.dialog.open(DeleteVehicleDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        deleteDoc(doc(this.firestore, 'vehicles', vehicle.id));
      }
    });
  }

  addVehicle() {
    this.dialog.open(AddVehicleComponent, {
      width: '70%',
    });
  }
}

@Component({
  selector: 'app-delete-vehicle-dialog',
  template: `
    <h2 mat-dialog-title>Delete?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Are you sure you want to delete this vehicle?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
    </mat-dialog-actions>
  `,
})
export class DeleteVehicleDialogComponent {}
