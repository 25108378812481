<div class="promobox">
  <div class="title-cards">
    <div class="title">
      <h1>Garage</h1>
      <p>A place for all your car related needs.</p>
      <p>Track everything about your car in one place.</p>
    </div>
    <div class="logo">
      <img
        src="/static/img/logo_no_text.svg"
        alt="Garage"
        class="home-logo"
        onerror="this.onerror=null; this.src='logo_no_text.png'"
      />
    </div>
  </div>
  <hr />
  <div class="cards">
    <div class="card">
      <h2>Garage</h2>
      <p>Make a garage with all of your cars and track each individually.</p>
    </div>
    <div class="card">
      <h2>Maintenance Records</h2>
      <p>Keep track of your car maintenance in one place. No more paper receipts!</p>
    </div>
    <div class="card">
      <h2>Charts</h2>
      <p>User focused charts provide a unique look at your expenses.</p>
    </div>
    <div class="card">
      <h2>Gas Mileage Tracking</h2>
      <p>
        Get rid of your gas receipts and track them online. Take a picture for future reference.
      </p>
    </div>
    <div class="card">
      <h2>Expense Manager</h2>
      <p>Track your car expenses with this easy to use tool. Categorize each expense.</p>
    </div>
    <div class="card">
      <h2>Mobile</h2>
      <p>
        Find gas prices on the go with the
        <a
          href="https://play.google.com/store/apps/details?id=com.worthwhilegames.carhubmobile"
          target="_blank"
          >mobile application</a
        >.
      </p>
    </div>
  </div>
</div>
