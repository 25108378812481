<div class="container">
  <div class="row">
    <div class="header-row">
      <h1>Your Garage</h1>
      <span class="spacer"></span>
      <button mat-fab color="primary" (click)="addVehicle()">
        <mat-icon aria-hidden="false">add</mat-icon>
      </button>
    </div>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Ford" #input />
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="year">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Year</th>
          <td mat-cell [routerLink]="['/vehicle', row.id]" *matCellDef="let row">
            {{ row.year }}
          </td>
        </ng-container>

        <ng-container matColumnDef="make">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Make</th>
          <td mat-cell [routerLink]="['/vehicle', row.id]" *matCellDef="let row">
            {{ row.make }}
          </td>
        </ng-container>

        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
          <td mat-cell [routerLink]="['/vehicle', row.id]" *matCellDef="let row">
            {{ row.model }}
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            [matMenuTriggerFor]="vehicleEditMenu"
            [matMenuTriggerData]="{ vehicle: row }"
            *matCellDef="let row"
          >
            <mat-icon>more_vert</mat-icon>
          </td>
          <mat-menu #vehicleEditMenu="matMenu">
            <ng-template matMenuContent let-vehicle="vehicle">
              <button mat-menu-item (click)="editVehicle(vehicle)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteVehicle(vehicle)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3" *ngIf="input.value === ''; else emptyFilter">
            You don't have any vehicles registered.
          </td>
          <ng-template #emptyFilter>
            <td class="mat-cell" colspan="3">
              No vehicles matching the filter "{{ input.value }}"
            </td>
          </ng-template>
        </tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
