import { Component } from '@angular/core';
import { Auth, authState, GoogleAuthProvider, signInWithPopup, User } from '@angular/fire/auth';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../common-styles.css', './header.component.css'],
})
export class HeaderComponent {
  public readonly user: Observable<User | null> = EMPTY;

  constructor(public auth: Auth) {
    this.user = authState(this.auth);
  }

  login() {
    signInWithPopup(this.auth, new GoogleAuthProvider());
  }
  logout() {
    this.auth.signOut();
  }
}
