<form [formGroup]="fuelForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ verb }} Fuel</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput formControlName="date" [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Location</mat-label>
        <input matInput formControlName="location" required />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Odometer</mat-label>
        <input matInput formControlName="odometer" required type="number" />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Price</mat-label>
        <input matInput formControlName="price" required type="number" />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Price Per Gallon</mat-label>
        <input matInput formControlName="pricePerGallon" required type="number" />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Fuel Grade</mat-label>
        <input matInput formControlName="grade" />
        <mat-hint>Regular, Premium</mat-hint>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" [disabled]="!fuelForm.valid" cdkFocusInitial>
      {{ verb }}
    </button>
  </mat-dialog-actions>
</form>
