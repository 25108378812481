import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { HomepageComponent } from './homepage/homepage.component';
import { VehicleComponent } from './vehicle/vehicle.component';

const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['']);

export const routes: Routes = [
  { path: '', component: HomepageComponent },
  {
    path: 'vehicle/:id',
    component: VehicleComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToHome },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
