<mat-toolbar color="primary" *ngIf="user | async as user; else showLogin">
  <button mat-button [routerLink]="'/'">
    <img src="/static/img/garagehub_logo.png" width="auto" alt="GarageHub" />
  </button>

  <span class="spacer"></span>

  <button mat-button [matMenuTriggerFor]="userMenu">
    <img src="{{ user.photoURL }}" alt="{{ user.displayName }}" /><span
      style="padding-left: 1rem"
      >{{ user.displayName }}</span
    ><mat-icon aria-hidden="false">keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="logout()">Logout</button>
  </mat-menu>
</mat-toolbar>
<ng-template #showLogin>
  <mat-toolbar color="primary">
    <button mat-button [routerLink]="'/'">
      <img src="/static/img/garagehub_logo.png" width="auto" alt="GarageHub" />
    </button>
    <span class="spacer"></span>
    <button mat-button (click)="login()">Login</button>
  </mat-toolbar>
</ng-template>
